import { createApp } from "vue";
import VueLazyload from "vue-lazyload";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";


import "./plugins/adaptive";
import "./styles/common.less";
import "./styles/vant.less";
import '@vant/touch-emulator';
import { myVanToast, myDialog } from "./plugins/vant";
import globalElementsMixins from "./plugins/elements";
import SvgIcon from "@/icons";
import api from "./service/api";

import "./contract/web3ModalConfig";
import web3Provider from "./contract/pool";
import web3 from "./contract/provider";

const app = createApp(App);
app.config.globalProperties.$web3 = web3;
app.config.globalProperties.$web3Provider = web3Provider;
app.config.globalProperties.$Toast = myVanToast;
app.use(myDialog());
app.mixin(globalElementsMixins);
app.component("svg-icon", SvgIcon);
app.config.globalProperties.$api = api;
app.use(store).use(i18n).use(router).use(VueLazyload).mount("#app");

import { Contract } from "web3-eth-contract";

const web3Provider = (type, abi, address, rpcProvider) => {
	// type 1是发送交易，0是读取链上信息
	if (type) {
		const contract = new Contract(abi, address);
		contract.setProvider(rpcProvider);
		return contract;
	} else {
		const contract = new Contract(abi, address);
		return contract;
	}
};

export default web3Provider;
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/vue";
import { CHAINS } from "@/constant/chain";
const projectId = process.env.VUE_APP_WC_PROJECT_ID;
const domainUrl = process.env.VUE_APP_WC_ORIGIN_DOMAIN;
const chains = process.env.NODE_ENV === "development" ? [CHAINS[9738]] : [CHAINS[8453]];
const defaultChains = process.env.NODE_ENV === "development" ? CHAINS[9738] : CHAINS[8453];
const metadata = {
    name: "Being",
    description: "Being.com",
    url: domainUrl, // origin must match your domain & subdomain
    icons: [""],
};

const ethersConfig = defaultConfig({
    metadata,
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: false, // true by default
    auth: {
        email: false,
    },
});
export const web3modal = createWeb3Modal({
    ethersConfig,
    chains,
    defaultChain: defaultChains,
    metadata,
    chainImages: {
        1: "https://raw.githubusercontent.com/HelloWorldDev/blockscout-frontend-configs/main/configs/network-logos/eth.png",
        9738: "https://raw.githubusercontent.com/HelloWorldDev/blockscout-frontend-configs/main/configs/network-logos/bome.png",
        9736: "https://raw.githubusercontent.com/HelloWorldDev/blockscout-frontend-configs/main/configs/network-logos/bome.png",
    },
    projectId,
    featuredWalletIds: [
        "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
        "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709",
    ],
    allWallets: "HIDE",
    enableEmail: false,
    enableSwaps: false,
    enableOnramp: false,
    themeVariables: {
        "--w3m-accent": "hsla(69, 100%, 48%, 0.8)",
        "--w3m-color-mix": "#000",
        "--w3m-color-mix-strength": 20,
        "--w3m-border-radius-master": "1.5px",
    },
});

//统一管理api
import { base_bome } from "./base"; // 导入接口域名列表
import { post } from "./tool"; // 导入http中创建的axios实例



export const gameList = (params) => post(base_bome + "/aibeing/water_game/latest", params);

export default {
    gameList,
};
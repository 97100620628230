import base_abi from "../contract/abi/base.json";
import being_Factory from "../contract/abi/BeingFactory.json";
import being_Pool from "../contract/abi/BeingPool.json";
import BEING from "../contract/abi/BEING.json";

export const CHAINS = {
    9736: {
        chainId: 9736,
        name: "Bome Mainnet",
        currency: "BOME",
        network: "Bome Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "BOME",
            symbol: "BOME",
        },
        rpcUrl: "https://rpc.bomechain.org",
        explorerUrl: "https://bomescan.org",
    },
    9738: {
        chainId: 9738,
        name: "Bome Testnet",
        currency: "BOME",
        network: "Bome Testnet",
        nativeCurrency: {
            decimals: 18,
            name: "BOME",
            symbol: "BOME",
        },
        rpcUrl: "https://testrpc.bomechain.org",
        explorerUrl: "https://testnet.bomescan.org",
    },
    1: {
        chainId: 1,
        name: "Ethereum Mainnet",
        currency: "ETH",
        network: "Ethereum Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        rpcUrl: "https://indulgent-lively-bird.quiknode.pro/5c31d7974c14704e77162ed8f1e29660b6d19270/",
        explorerUrl: "https://etherscan.io",
    },
    56: {
        chainId: 56,
        name: "BNB Smart Chain Mainnet",
        currency: "BNB",
        network: "BNB Smart Chain Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "BNB",
            symbol: "BNB",
        },
        rpcUrl: "https://neat-practical-arrow.bsc.quiknode.pro/b2f485b14431f07a8e9e25951ad16fb364a0dd3a/",
        explorerUrl: "https://bscscan.com",
    },
    97: {
        chainId: 97,
        name: "BNB Smart Chain Testnet",
        currency: "tBNB",
        network: "BNB Smart Chain Testnet",
        nativeCurrency: {
            decimals: 18,
            name: "tBNB",
            symbol: "tBNB",
        },
        rpcUrl: "https://data-seed-prebsc-1-s3.bnbchain.org:8545",
        explorerUrl: "https://testnet.bscscan.com",
    },
    8453: {
        chainId: 8453,
        name: "Base Mainnet",
        currency: "ETH",
        network: "Base Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        rpcUrl: "https://shy-dry-bird.base-mainnet.quiknode.pro/b1b7709a7afb1f86ce2184930060f108d2de2214/",
        explorerUrl: "https://basescan.org",
    },
    84532: {
        chainId: 84532,
        name: "Base Sepolia Testnet",
        currency: "ETH",
        network: "Base Sepolia Testnet",
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        rpcUrl: "https://sepolia.base.org",
        explorerUrl: "https://base-sepolia.blockscout.com",
    },
};

export const ABISDATA = {
    9738: {
        base_abi,
        being_Factory,
        being_Pool,
        BEING
    },
    8453: {
        base_abi,
        being_Factory,
        being_Pool,
        BEING
    }
};
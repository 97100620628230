const Home = () => import("@/views/Home");
const Real = () => import("@/views/Real");
const RealLoading = () => import("@/views/Real/loading.vue");
// const Matrix = () => import("@/views/Matrix/index.vue");
const MatrixLoading = () => import("@/views/Matrix/loading.vue");
// const End = () => import("@/views/Matrix/end.vue");
const Game = () => import("@/views/Matrix/game.vue");
const Me = () => import("@/views/Me/index.vue");

const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        component: () => import('@/layouts'),
        children: [
            {
                path: '/',
                name: 'home',
                component: Home,
            },
            {
                path: '/real',
                name: 'real',
                component: Real,
            },
            {
                path: '/real/loading',
                name: 'realLoading',
                component: RealLoading,
            },
            // {
            //     path: '/matrix',
            //     name: 'matrix',
            //     component: Matrix,
            // },
            {
                path: '/matrix/loading',
                name: 'matrixLoading',
                component: MatrixLoading,
            },
            // {
            //     path: '/soon',
            //     name: 'soon',
            //     component: End,
            // },
            {
                path: '/matrix/game',
                name: 'matrixGame',
                component: Game,
            },
            {
                path: '/me',
                name: 'me',
                component: Me,
            },
        ]
    },
];
export default routes;
